


::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


  body {
    background-color: #f0f0f0; /* 灰色の背景色 */
  }

  .Profile {
    background-color: #f0f0f0; /* 灰色の背景色 */
    width: 100%;
    height: 100vh;

  }
